/* eslint-disable func-names */
import React from "react";
// import CN from "./flags/cn";
// import CZ from "./flags/cz";
import DE from "./flags/de";
import EN from "./flags/en";
// import FR from "./flags/fr";
// import IT from "./flags/it";
// import PL from "./flags/pl";
// import PT from "./flags/pt";
// import SK from "./flags/sk";
// import UA from "./flags/ua";
// import CN_SAFARI from "./flagsSafari/cn";
// import CZ_SAFARI from "./flagsSafari/cz";
import EN_SAFARI from "./flagsSafari/en";
import DE_SAFARI from "./flagsSafari/de";
// import FR_SAFARI from "./flagsSafari/fr";
// import IT_SAFARI from "./flagsSafari/it";
// import PL_SAFARI from "./flagsSafari/pl";
// import PT_SAFARI from "./flagsSafari/pt";
// import SK_SAFARI from "./flagsSafari/sk";
// import UA_SAFARI from "./flagsSafari/ua";

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window.safari || (typeof safari !== "undefined" && window.safari.pushNotification));

export const supportedLanguages = [
  {
    value: "en",
    label: "EN",
    locale: "en-UK",
    datePickerLocale: "en-GB",
    flag: isSafari ? <EN_SAFARI /> : <EN />,
  },
  {
    value: "de",
    label: "DE",
    locale: "de-DE",
    datePickerLocale: "de",
    flag: isSafari ? <DE_SAFARI /> : <DE />,
  },
  // {
  //   value: "cn",
  //   label: "CN",
  //   locale: "zh-CN",
  //   datePickerLocale: "zh-CN",
  //   flag: isSafari ? <CN_SAFARI /> : <CN />,
  // },
  // {
  //   value: "cz",
  //   label: "CZ",
  //   locale: "cs-CZ",
  //   datePickerLocale: "cs",
  //   flag: isSafari ? <CZ_SAFARI /> : <CZ />,
  // },
  // {
  //   value: "fr",
  //   label: "FR",
  //   locale: "fr-FR",
  //   datePickerLocale: "fr",
  //   flag: isSafari ? <FR_SAFARI /> : <FR />,
  // },
  // {
  //   value: "it",
  //   label: "IT",
  //   locale: "it-IT",
  //   datePickerLocale: "it",
  //   flag: isSafari ? <IT_SAFARI /> : <IT />,
  // },
  // {
  //   value: "pl",
  //   label: "PL",
  //   locale: "pl-PL",
  //   datePickerLocale: "pl",
  //   flag: isSafari ? <PL_SAFARI /> : <PL />,
  // },
  // {
  //   value: "pt",
  //   label: "PT",
  //   locale: "pt-PT",
  //   datePickerLocale: "pt",
  //   flag: isSafari ? <PT_SAFARI /> : <PT />,
  // },
  // {
  //   value: "sk",
  //   label: "SK",
  //   locale: "sk-SK",
  //   datePickerLocale: "sk",
  //   flag: isSafari ? <SK_SAFARI /> : <SK />,
  // },
  // {
  //   value: "ua",
  //   label: "UA",
  //   locale: "uk-UA",
  //   datePickerLocale: "uk",
  //   flag: isSafari ? <UA_SAFARI /> : <UA />,
  // },
];
