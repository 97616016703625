import styled from "styled-components/macro";
import { Button } from "@xyndata/collaboration_ui";

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  margin-right: 16px;
`;
