import { takeLatest, call, select } from "redux-saga/effects";
import { store, CONSTANTS } from "@xyndata/communication_app_common";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

export function* fetchUpcomingMeetingsSaga(request) {
  const params = yield select(store.meetings.selectors.selectParams);

  yield call(fetchSaga, {
    api: request,
    onSuccess: store.meetings.actions.requestSuccess,
    onError: store.meetings.actions.requestError,
    data: {
      ...params,
      order: "cr.meeting_date:asc",
      filter: {
        $op: "and",
        "cr.meeting_date": {
          $gte: new Date(),
        },
        "cr.meeting_type": {
          $eq: CONSTANTS.MEETING_TYPE.SCHEDULED,
        },
      },
    },
  });
}

function* meetingsSaga() {
  yield takeLatest(store.meetings.actions.requestStart.type, fetchUpcomingMeetingsSaga, api.meetings.getAll);
}

export default meetingsSaga;
