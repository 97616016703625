/* eslint-disable no-param-reassign */
import ROUTES from "../constants/routes";
import { getIsTokenFromURL } from "./authToken";
import { dispatchToBridge } from "./delphiBridge";
import { getLocale } from "./formatTime";

export const openMeeting = (hash, windowRef) => {
  if (getIsTokenFromURL()) {
    dispatchToBridge({
      type: "OPEN_MEETING",
      payload: {
        hash,
      },
    });
  } else {
    const location = ROUTES.VIDEOROOM.replace(":hash", hash);
    if (windowRef) {
      windowRef.location = location;
    } else {
      window.open(location);
    }
  }
};

export const byteFormatting = (size) => {
  if (size < 1000) {
    return `${size}B`;
  }
  if (size < 1000000) {
    return `${(size / 1000).toFixed(1)}KB`;
  }

  return `${(size / 1000000).toFixed(1)}MB`;
};

export const formattedBitrate = (language, bitrate) =>
  `${new Intl.NumberFormat(getLocale(language)).format(~~bitrate / 1024)}kbps`;

export const getApiErrorTranslation = (intl, errorResponse) => {
  if (!errorResponse) {
    return intl.formatMessage({ id: "ui.defaultError" });
  }

  const errorMessage = errorResponse?.response?.data?.message;
  if (errorMessage) {
    return intl.messages[errorMessage] ? intl.formatMessage({ id: errorMessage }) : errorMessage;
  }

  return errorResponse.message;
};

export const isUserInvitedToMeeting = (userId, meeting) => {
  return !!(
    userId &&
    meeting.id &&
    (meeting.created_by === userId ||
      meeting.created_by_id === userId ||
      (meeting?.invitations?.length > 0 && meeting.invitations.some(({ created_for }) => created_for === userId)) ||
      (meeting?.participants?.length > 0 && meeting.participants.some(({ id }) => id === userId)))
  );
};
