import { takeLatest, select, call } from "redux-saga/effects";
import { store } from "@xyndata/communication_app_common";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

export function* fetchNotificationsSaga(request) {
  const params = yield select(store.notifications.selectors.selectParams);
  yield call(fetchSaga, {
    api: request,
    onSuccess: store.notifications.actions.requestSuccess,
    onError: store.notifications.actions.requestError,
    data: params,
  });
}

export function* cleanNotificationsSaga(request, { payload }) {
  yield call(fetchSaga, {
    api: request,
    onSuccess: store.notifications.actions.cleanNotificationsSuccess,
    onError: store.notifications.actions.requestError,
    data: payload,
  });
}

function* notificationsSaga() {
  yield takeLatest(store.notifications.actions.requestStart.type, fetchNotificationsSaga, api.notifications.get);
  yield takeLatest(
    store.notifications.actions.cleanNotifications.type,
    cleanNotificationsSaga,
    api.notifications.clear
  );
}

export default notificationsSaga;
