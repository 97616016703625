/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import { isEdge } from "./browserDetection";
import logger from "./logger";

export const dispatchToBridge = ({ type, payload }) => {
  let obj = {};
  try {
    var parameters = JSON.stringify({ type, payload });

    if (isEdge()) {
      obj = window?.chrome?.webview?.hostObjects?.MyBridge;
      if (obj) {
        // Object.defineProperty(obj, "ObjectMessage", {});
        obj.ObjectMessage = parameters;
      }
    } else {
      window.webkit.messageHandlers.MyBridge.postMessage(parameters);
    }
  } catch (error) {
    logger.log(`dispatchToBridge error`);
    logger.log(type);
    logger.log(payload);
    logger.log(obj);
    logger.error(error);
  }
};
