import { defaultTheme } from "@xyndata/collaboration_ui";
import { helpers, CONSTANTS } from "@xyndata/communication_app_common";

const fonts = {
  primary: "'Roboto', sans-serif;",
};

const fontSizes = {
  ...defaultTheme.fontSizes,
  extraSmall: "10px",
};

const gutters = {
  sm: 32,
  md: 16,
  lg: 24,
};

const breakpoint = {
  sm: 375,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1366,
  xxxl: 1666,
};

const devices = {
  min: {
    mobile: `(min-width: ${breakpoint.sm}px)`,
    tablet: `(min-width: ${breakpoint.md}px)`,
    laptop: `(min-width: ${breakpoint.lg}px)`,
    desktop: `(min-width: ${breakpoint.xl}px)`,
    desktopXxl: `(min-width: ${breakpoint.xxl}px)`,
  },
  max: {
    mobile: `(max-width: ${breakpoint.sm - 1}px)`,
    tablet: `(max-width: ${breakpoint.md - 1}px)`,
    laptop: `(max-width: ${breakpoint.lg - 1}px)`,
    desktop: `(max-width: ${breakpoint.xl - 1}px)`,
    desktopXxl: `(max-width: ${breakpoint.xxl - 1}px)`,
    desktopXxxl: `(max-width: ${breakpoint.xxxl - 1}px)`,
  },
};

const transitions = {
  main: `all 150ms ease-in-out`,
};

const colors = {
  ...defaultTheme.colors,
  yellow: "#FFCE00",
  ronchi: "#EEC14C",
  error: defaultTheme.colors.red,
  info: defaultTheme.colors.primary,
};

const icons = {
  defaultIconFill: colors.white,
};

const mainBgColor = colors.blue.lighten45;
const homePageBgColor = colors.blue.lighten45;

const header = {
  headerColor: colors.blue.default,
  headerTextColor: colors.white,
  defaultMenuItemColor: "rgba(255, 255, 255, 0.7)",
  defaultMenuItemHoverColor: colors.white,
  defaultMenuItemFocusColor: colors.white,
  activeHeaderMenuBgColor: colors.blue.lighten45,
  headerHeight: 80,
  headerDropdownBg: colors.white,
};

const layout = {
  layoutPaddingTop: 40, // px
  layoutPaddingBottom: 56, // px
  layoutPaddingMobile: 20, // px
  meetingRoomSidebarWidth: 392, // px
};

const footer = {
  footerColor: colors.gray.lighten20,
  footerTextFontSize: fontSizes.extraSmall,
};

const linkButton = {
  linkButtonColor: colors.blue.default,
};

const meeting = {
  meetingActionPanelBgColor: colors.blue.darken35,
  meetingDateInputTitleColor: colors.gray.default,
  permanentMeetingStatusTitleColor: colors.gray.darken10,
};

const modals = {
  modalTitleColor: colors.blue.darken35,
};

const dashboard = {
  dashboardMeetingDetailsSecondaryTextColor: colors.gray.darken10,
};

const statusesColors = {
  [CONSTANTS.USER_STATUSES.ONLINE]: colors.secondary.default,
  [CONSTANTS.USER_STATUSES.OFFLINE]: colors.gray.lighten20,
  [CONSTANTS.USER_STATUSES.BUSY]: colors.red.default,
  [CONSTANTS.USER_STATUSES.MEETING]: colors.primary.default,
  [CONSTANTS.USER_STATUSES.AWAY]: colors.yellow,
};

const dataTable = {
  tableBackgroundColor: colors.white,
  tableSortingChevronColor: colors.gray.default,
  tableHeaderBorder: colors.gray.lighten20,
  tableHeaderColor: colors.gray.lighten5,
  tableTextColor: colors.gray.default,
  tableHeaderHeight: 48, // px
};

const contacts = {
  contactsNameColor: colors.blue.darken35,
};

const videoStream = {
  videoStreamTextColor: colors.blue.darken35,
  videoStreamBgColor: colors.gray.default,
  videoStreamOverlayColor: helpers.convertHexToRGBA(colors.white, 0.8),
  videoStreamAlertColor: colors.red.default,
  videoStreamEqualizerColor: colors.secondary.default,
};

const changeLayoutModal = {
  changeLayoutModalTitleColor: colors.blue.darken35,
  changeLayoutModalButtonTitleColor: colors.gray.darken10,
  changeLayoutModalButtonColor: colors.gray.lighten20,
  changeLayoutModalActiveButtonColor: colors.gray.default,
};

const contactGroup = {
  contactGroupParticipantsTitleColor: colors.gray.darken15,
  contactGroupNoParticipantsTextColor: colors.gray.lighten20,
};

const homePage = {
  homePageTitleColor: colors.blue.default,
};

const resetPassword = {
  resetPasswordBg: colors.white,
  resetPasswordTitleColor: colors.blue.darken35,
  resetPasswordSubtitleColor: colors.navyBlue,
};

const profile = {
  settingCategoryBorderBottomColor: colors.gray.lighten25,
  profilePhotoBtnColor: colors.blue.default,
};

const toastNotifications = {
  notificationBg: colors.white,
  notificationSuccessColor: colors.secondary.default,
  notificationErrorColor: colors.red.default,
  notificationInfoColor: colors.blue.darken35,
  notificationCloseColor: colors.gray.default,
};

const zIndex = {
  zIndexDefault: 1,
  zIndexDropdown: 4,
  zIndexModal: 6,
  zIndexOverlay: 9,
  zIndexAbsoluteOverlay: 99,
  zIndexUnderlay: 0,
};

const typography = {
  typographyColor: colors.blue.darken35,
  typographyFontSize: "18px",
  typographyLineHeight: "16px",
  typographyH5FontSize: "24px",
  typographyH5LineHeight: "32px",
  typographyH6FontSize: "20px",
  typographyH6LineHeight: "24px",
  typographyBody1FontSize: "16px",
  typographyBody1LineHeight: "24px",
  typographyBody2FontSize: "14px",
  typographyBody2LineHeight: "20px",
  typographyButtonLineHeight: "24px",
  typographyButtonFontSize: "16px",
  typographyCaptionFontSize: "12px",
  typographyCaptionLineHeight: "16px",
  typographyCaption2FontSize: "10px",
  typographyCaption2LineHeight: "16px",
};

const uploader = {
  uploaderDashedBorderColor: colors.gray.lighten20,
  uploadAreaBg: colors.white,
  uploadAreaHoverBg: colors.gray.lighten35,
  uploaderCloudColor: colors.blue.lighten40,
  uploaderCloudHoverColor: colors.blue.lighten35,
  uploaderCloudDropColor: colors.blue.lighten20,
  uploaderCancelUploadBg: "rgba(4, 39, 81, 0.6)",
};

const theme = {
  ...defaultTheme,
  name: "NEW",
  fonts,
  mainBgColor,
  homePageBgColor,
  breakpoint,
  devices,
  gutters,
  statusesColors,
  transitions,
  modals,
  ...toastNotifications,
  ...videoStream,
  ...dataTable,
  ...header,
  ...contacts,
  ...contactGroup,
  ...icons,
  ...footer,
  ...homePage,
  ...linkButton,
  ...layout,
  ...profile,
  ...resetPassword,
  ...typography,
  ...meeting,
  ...changeLayoutModal,
  ...dashboard,
  ...uploader,
  ...zIndex,
  inputBgColor: colors.white,
  colors: {
    ...defaultTheme.colors,
    ...colors,
  },
};

export default theme;
