const ROUTES = {
  HOME: "/",
  HOME_REDIRECT: "/?redirectTo=url",
  GUEST_JOIN: "/join",
  GUEST_JOIN_PARAMS: "/guestjoin/:hash/:invitationToken",
  VIDEOROOM: "/videoroom/:hash",
  VIDEOROOM_HEADLESS: "/videoroom-headless/:meetingHash/:attendeeId",
  CONTACTS: "/contacts",
  MEETINGS: "/meetings",
  RESET_PASSWORD: "/reset-password/:code",
  DEVICE_TEST: "/device-test",
  DECLINE_INVITE: "/decline-invitation/:invitationToken",
  USER_INVITATION: "/user-invitation/:invitationType/:hash/:invitationToken",
  GUEST_INVITATION: "/guest-invitation/:invitationType/:hash/:invitationToken",
  PROFILE: "/profile/:activeTab",
  GUEST_JOIN_IFRAME: "/guest-join-iframe",
  RECORDING: "/recording/:meetingId/:recordingId",
};

const getRouteFromPath = (path, ...params) => {
  let count = -1;
  return path.replace(/:[a-zA-Z?]+/g, (match) => {
    count += 1;
    return params[count] !== undefined ? params[count] : match;
  });
};

export const editProfileRoute = getRouteFromPath(ROUTES.PROFILE, "edit");
export const settingsProfileRoute = getRouteFromPath(ROUTES.PROFILE, "settings");
export const mediaSettingsProfileRoute = getRouteFromPath(ROUTES.PROFILE, "media");

export default ROUTES;
