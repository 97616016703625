import * as React from "react";

const deFlag = (props) => (
  <svg width={34} height={30} viewBox="0 0 34 30" fill="none" {...props}>
    <path d="M5 17.334h24V24H5v-6.666z" fill="#FFCE00" />
    <path d="M5 4h24v6.667H5V4z" fill="#000" />
    <path d="M5 10.666h24v6.667H5v-6.666z" fill="#D00" />
  </svg>
);

export default deFlag;
