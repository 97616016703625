import { store, CONSTANTS } from "@xyndata/communication_app_common";

const { meeting, meetings, onAirMeetings, users, auth } = store;

const meetingMiddleware = ({ dispatch, getState }) => {
  return (next) => async (action) => {
    switch (action.type) {
      case CONSTANTS.EVENTS_TYPES.MEETING_USER_JOINED: {
        // refetch users on new user join
        dispatch(users.actions.requestStart());
        break;
      }
      case CONSTANTS.EVENTS_TYPES.MEETING_STATUS: {
        const state = getState();

        const user = auth.selectors.selectUser(state);

        if (user && !user.guest) {
          dispatch(onAirMeetings.actions.requestStart());
        }

        if (action.payload.meeting.meeting_type === CONSTANTS.MEETING_TYPE.SCHEDULED) {
          const upcomingMeetings = meetings.selectors.selectData(state);
          // refetch all upcoming meetings if meeting not exist in list
          if (!upcomingMeetings.find(({ id }) => id === action.payload.meeting.id)) {
            dispatch(meetings.actions.requestStart());
          }
        }

        if (!state.meeting.id || action.payload.meeting.id === state.meeting.id) {
          dispatch(meeting.actions.updateMeeting(action.payload.meeting));
        }

        break;
      }
      default:
        break;
    }

    return next(action);
  };
};

export default meetingMiddleware;
