import styled from "styled-components";
import { rgba } from "polished";
import { Button } from "@xyndata/collaboration_ui";

export const MaintenanceBarContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndexAbsoluteOverlay};
  background: ${({ theme }) => theme.colors.white};
`;

export const Maintenance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 72px;
  padding: 0 64px;
  box-shadow: ${({ theme }) => theme.shadows.dp6};
  background: ${({ theme, type }) => (type === "info" ? theme.colors.white : rgba(theme.colors[type].default, 0.15))};
`;

export const CloseButton = styled.button`
  display: flex;
  position: absolute;
  top: 20px;
  right: 16px;
  border: 0;
  padding: 8px;
  background: transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const ActionButton = styled(Button)`
  white-space: nowrap;
  margin-left: 16px;
  flex: none;
`;
