import * as React from "react";

const enFlag = (props) => (
  <svg width={34} height={30} viewBox="0 0 34 30" fill="none" {...props}>
    <g filter="url(#prefix__filter0_ddd_en)">
      <g clipPath="url(#prefix__clip0_en)">
        <path d="M-.95 4h35.556v20H-.95V4z" fill="#012169" />
        <path
          d="M-.95 4v2.234L30.63 24h3.976v-2.234L3.026 4H-.95zm35.556 0v2.234L3.026 24H-.95v-2.234L30.63 4h3.976z"
          fill="#fff"
        />
        <path d="M13.866 4v20h5.924V4h-5.924zM-.95 10.668v6.664h35.556v-6.664H-.95z" fill="#fff" />
        <path
          d="M-.95 12v4h35.556v-4H-.95zm16-8v20h3.556V4H15.05zm-16 20l11.851-6.668h2.653L1.7 24H-.95zm0-20l11.851 6.668h-2.65l-9.2-5.176V4zm21.056 6.668L31.956 4h2.65l-11.85 6.668h-2.65zM34.606 24l-11.85-6.668h2.648l9.202 5.176V24z"
          fill="#C8102E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="prefix__clip0_en">
        <path d="M5 8a4 4 0 014-4h16a4 4 0 014 4v12a4 4 0 01-4 4H9a4 4 0 01-4-4V8z" fill="#fff" />
      </clipPath>
      <filter
        id="prefix__filter0_ddd_en"
        x={0}
        y={0}
        width={34}
        height={30}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2.5} />
        <feColorMatrix values="0 0 0 0 0.368627 0 0 0 0 0.443137 0 0 0 0 0.54902 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0.368627 0 0 0 0 0.443137 0 0 0 0 0.54902 0 0 0 0.14 0" />
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0.368627 0 0 0 0 0.443137 0 0 0 0 0.54902 0 0 0 0.2 0" />
        <feBlend in2="effect2_dropShadow" result="effect3_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default enFlag;
