import { createGlobalStyle, css } from "styled-components";
import { normalize } from "styled-normalize";
import { helpers } from "@xyndata/communication_app_common";

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  *, body {
    font-family:  ${({ theme }) => theme.fonts.primary};
    font-weight: 400;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }
  
  body {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.blue.lighten45};
  }

  b {
    font-weight: bold;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  /* Toast notifications */
  .Notifier.Toastify__toast {
    display: flex;
    align-items: center;
    width: 370px;
    margin-bottom: 16px;
    border-radius: 24px;
    padding: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    background-color: ${({ theme }) => theme.notificationBg};
    box-shadow: ${({ theme }) => theme.shadows.dp24};
  }


  .Notifier.Toastify__toast--success {
    color: ${({ theme }) => theme.notificationSuccessColor};
    background-color: ${({ theme }) => theme.colors.white};
   

    path {
      fill: ${({ theme }) => theme.notificationSuccessColor};
    }
  }

  .Notifier .Toastify__toast--error {
    color: ${({ theme }) => theme.notificationErrorColor};
    background-color: ${({ theme }) => theme.colors.white};

    path {
      fill: ${({ theme }) => theme.notificationErrorColor};
    }
  }

  .Notifier.Toastify__toast--info {
    color: ${({ theme }) => theme.notificationInfoColor};

    path {
      fill: ${({ theme }) => theme.notificationInfoColor};
    }
  }

  .Notifier.Toastify__toast--warning {
    color: ${({ theme }) => theme.notificationInfoColor};

    path {
      fill: ${({ theme }) => theme.notificationInfoColor};
    }
  }

  .Notifier .Toastify__close-button {
    align-self: center;
    path {
      fill: ${({ theme }) => theme.notificationCloseColor};
    }
  }

  .Notifier .hide_close_button .Toastify__close-button {
    display: none;
  }

  .Notifier .Toastify__progress-bar--animated {
    background-color: ${({ theme }) => theme.colors.gray.lighten25};  
    margin-left: 15px;
    width: 90%;
  }

  .Notifier .Toastify__close-button:hover, .Notifier .Toastify__close-button:focus {
    opacity: 0.8;
  }

  .Notifier.Toastify__toast-container--top-right {
    top: 12px;
    right: 0;
  }


  .Notifier.Toastify__toast-container {
    width: auto;
    max-width: 425px;
    min-width: 300px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 24px 45px 45px;
  }

  .Notifier .toast-rounded {
    &.Toastify__toast{
      box-shadow: none;
      box-shadow: ${({ theme }) => theme.shadows.dp24};
      background: ${({ theme }) => theme.colors.white};
      border-radius: 32px;
      padding: 16px;
      overflow: initial;
    }
  }

  /* date-picker-custom-popover */
  .date-picker-custom-popover {
    & .react-datepicker__triangle {
      display: none;
    }
  }

  /* tooltip */

  .custom-tooltip {
    z-index: 9999;
  }

  /* date-picker-custom-calendar */
  
  .date-picker-custom-calendar {
    font-family:  ${({ theme }) => theme.fonts.primary};
    border: 0;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadows.dp6};
    border-radius: 32px;
    padding: 16px 6px;

    &.react-datepicker--time-only {
      padding: 12px 0 12px 12px;

      .react-datepicker-time__header, .react-datepicker__header--time {
        display: none;
      }

       .react-datepicker__time-box {
        width: auto;
       }

      .react-datepicker__time-container {
        width: auto;

      & .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        min-width: 120px;
      }

      & .react-datepicker__time .react-datepicker__time-box {
        width: 120px;
      }

      & .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        font-size: 18px;
        line-height: 24px;
        font-style: normal;
        font-weight: normal;
        color: ${({ theme }) => theme.colors.gray.darken10};
        height: auto;
        padding: 4px 12px;
        border-radius: 8px;
        margin: 4px 0;
        text-align: left;

        &:hover {
          background: ${({ theme }) => helpers.convertHexToRGBA(theme.colors.primary.default, 0.08)};
        }

        &--selected {
          color: ${({ theme }) => theme.colors.white};
          background: ${({ theme }) => theme.colors.primary.default};
  
          &:hover {
            background: ${({ theme }) => theme.colors.primary.default};
          }
        }

        &--disabled {
          color: ${({ theme }) => theme.colors.gray.lighten25};
  
          &:hover {
            background: transparent;
          }
        }
      }

      }

    }

    .react-datepicker {
      &__navigation{
        top: 28px; 
        outline: none;

        &--next {
          border-left-color: ${({ theme }) => theme.colors.blue.darken35};
        }

        &--previous {
          border-right-color: ${({ theme }) => theme.colors.blue.darken35};
        }

        &:hover {
          opacity: 0.8;
        }
      }

      &__header {
        background: transparent;
        border: 0;
      }

      &__current-month, &-time__header, &-year-header {
        font-style: normal;
        font-weight: normal;
        font-family:  ${({ theme }) => theme.fonts.primary};
        font-size: 14px;
        line-height: 20px;
        color:  ${({ theme }) => theme.colors.blue.darken35};
      }

      &__day {
        color: ${({ theme }) => theme.colors.gray.darken10};
        width: 32px;
        height: 32px;
        line-height: 32px;
        margin: 2px 2px 3px 2px;
        font-size: 14px;
        font-family:  ${({ theme }) => theme.fonts.primary};
        background: ${({ theme }) => theme.colors.white};

        &:hover {
          color: ${({ theme }) => theme.colors.gray.darken10};
          border-radius: 50%;
          background: ${({ theme }) => helpers.convertHexToRGBA(theme.colors.primary.default, 0.08)};
        }

        &--today {
          color: ${({ theme }) => theme.colors.primary.default};
          font-weight: normal;
        }

        &-name{ 
          font-size: 14px;
          line-height: 20px;
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin: 3px 3px 0 3px;
          color: ${({ theme }) => theme.colors.gray.lighten25};
        }

        &--disabled {
          color: ${({ theme }) => theme.colors.gray.lighten25};
        }

        &--selected {
          background: ${({ theme }) => theme.colors.primary.default};
          color: ${({ theme }) => theme.colors.white};
          border-radius: 50%;
        }
      } 

      &__time-container &__time &__time-box ul&__time-list li&__time-list-item--selected {
        background: ${({ theme }) => theme.colors.primary.default};
        line-height: 1.5em;
      }
    }
  }

`;

export const hoverStyles = css`
  &:focus:enabled {
    background-color: ${({ theme }) => theme.inputHoverBackgroundColor};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.inputHoverBorderColor};
  }

  &:hover:enabled {
    background-color: ${({ theme }) => theme.inputHoverBackgroundColor};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.inputHoverBorderColor};
  }
`;

export default GlobalStyle;
