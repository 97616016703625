import * as React from "react";

const enFlag = (props) => (
  <svg width={34} height={30} viewBox="0 0 34 30" fill="none" {...props}>
    <path d="M-.95 4h35.556v20H-.95V4z" fill="#012169" />
    <path
      d="M-.95 4v2.234L30.63 24h3.976v-2.234L3.026 4H-.95zm35.556 0v2.234L3.026 24H-.95v-2.234L30.63 4h3.976z"
      fill="#fff"
    />
    <path d="M13.866 4v20h5.924V4h-5.924zM-.95 10.668v6.664h35.556v-6.664H-.95z" fill="#fff" />
    <path
      d="M-.95 12v4h35.556v-4H-.95zm16-8v20h3.556V4H15.05zm-16 20l11.851-6.668h2.653L1.7 24H-.95zm0-20l11.851 6.668h-2.65l-9.2-5.176V4zm21.056 6.668L31.956 4h2.65l-11.85 6.668h-2.65zM34.606 24l-11.85-6.668h2.648l9.202 5.176V24z"
      fill="#C8102E"
    />
  </svg>
);

export default enFlag;
