import { useLocation } from "react-router-dom";

/**
 *
 * @param {string} name - search param name
 * @param {boolean} isBoolean - should param to be checked as boolean
 * @returns
 */
// TODO: add check if name is array and then return array of params
const useURLSearchParam = (name, isBoolean) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const param = isBoolean ? query.get(name) === "true" : query.get(name);

  return param || "";
};

export default useURLSearchParam;
