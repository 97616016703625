import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { store, CONSTANTS } from "@xyndata/communication_app_common";
import { childrenPropTypes } from "../../constants/propTypes";
import messages from "../../translations";

const IntlProviderWrapper = ({ children, language }) => {
  const localeMessages = { ...messages[CONSTANTS.DEFAULT_LANGUAGE], ...messages[language] };

  return (
    <IntlProvider locale={language} messages={localeMessages}>
      {children}
    </IntlProvider>
  );
};

IntlProviderWrapper.propTypes = {
  children: childrenPropTypes.isRequired,
  language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  language: store.app.selectors.selectLanguage(state),
});

export default connect(mapStateToProps, {})(IntlProviderWrapper);
