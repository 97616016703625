import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import createSagaMiddleware from "redux-saga";
import { rootReducer as commonReducers, store } from "@xyndata/communication_app_common";
import alertMiddleware from "../middleware/alertMiddleware";
import wsMiddleware from "../middleware/wsMiddleware";
import authMiddleware from "../middleware/authMiddleware";
import meetingMiddleware from "../middleware/meetingMiddleware";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

const createRootReducer = () => {
  return (state, action) => commonReducers(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === store.auth.actions.requestLogin) {
      // Return a transformed action to remove sensitive information
      return {
        ...action,
        password: null,
      };
    }

    return action;
  },
  configureScopeWithState: (scope, state) => {
    // Set tag if the user logged in
    if (state?.auth?.user?.id) {
      scope.setTag("userId", state.auth.user.id);
    } else {
      scope.setTag("userId", null);
    }
  },

  stateTransformer: (state) => {
    const { alerts, tos, auth, users, ...restState } = state;
    let authStore = auth;

    if (auth?.user) {
      authStore = { ...auth, user: { ...auth.user, tos_text: "removed" } };
    }

    return { auth: authStore, ...restState };
  },
});

const enhancers = (defaultEnhancers) => [sentryReduxEnhancer, ...defaultEnhancers];

export const rootReducer = createRootReducer();

const createStore = (preloadedState) => {
  const middleware = [
    ...getDefaultMiddleware({ thunk: false }),
    wsMiddleware,
    alertMiddleware,
    sagaMiddleware,
    authMiddleware,
    meetingMiddleware,
  ];

  const isDevMode = process.env.NODE_ENV !== "production";

  const rootStore = configureStore({
    reducer: rootReducer,
    devTools: isDevMode,
    middleware,
    preloadedState,
    enhancers,
  });

  sagaMiddleware.run(rootSaga);

  return rootStore;
};

export default createStore;
