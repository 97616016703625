import { store } from "@xyndata/communication_app_common";

const authMiddleware = ({ dispatch, getState }) => {
  const state = getState();
  return (next) => (action) => {
    switch (action.type) {
      case store.auth.actions.authorizeSuccess.type:
        // if user authorize and version was updated, or if  tos not accepted show tos modal
        if (
          (state.auth.user && state.auth.user.tos_version !== action.payload.tos_version) ||
          !action.payload.tos_accepted
        ) {
          dispatch(store.tos.actions.showModal());
        }
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default authMiddleware;
