import PropTypes from "prop-types";

export const userPropTypes = PropTypes.shape({
  id: PropTypes.string,
  username: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.string,
});

export const usersPropTypes = PropTypes.object;

export const streamPropTypes = PropTypes.shape({
  active: PropTypes.bool,
  id: PropTypes.string,
  onactive: PropTypes.func,
  onaddtrack: PropTypes.func,
  oninactive: PropTypes.func,
  onremovetrack: PropTypes.func,
});

export const locationPropTypes = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
  state: PropTypes.shape({
    backUrl: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }),
});

export const participantPropTypes = PropTypes.shape({
  user_id: PropTypes.string,
  id: PropTypes.string,
  full_name: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  nickname: PropTypes.string,
  attendeeId: PropTypes.number,
  audio: PropTypes.bool,
  isOwner: PropTypes.bool,
  video: PropTypes.bool,
  moderator: PropTypes.bool,
  image: PropTypes.string,
});
// TODO check why we use singleParticipantPropTypes in different components and value return different property
export const singleParticipantPropTypes = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.string),
  entities: PropTypes.shape({
    id: PropTypes.string,
    moderator: PropTypes.bool,
  }),
  created_at: PropTypes.string,
  created_by: PropTypes.string,
  duration: PropTypes.number,
  favorite: PropTypes.bool,
  hash: PropTypes.string,
  id: PropTypes.string,
  media_core_room_id: PropTypes.string,
  media_core_room_pin: PropTypes.string,
  meeting_date: PropTypes.string,
  meeting_type: PropTypes.string,
  name: PropTypes.string,
  updated_at: PropTypes.string,
});

export const attendeePropTypes = PropTypes.shape({
  id: PropTypes.number,
  userId: PropTypes.string,
  nickname: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  audio: PropTypes.bool,
  video: PropTypes.bool,
  talking: PropTypes.bool,
  iceConnectionState: PropTypes.string,
  updated: PropTypes.number,
});

export const invitationPropTypes = PropTypes.shape({
  id: PropTypes.string,
  invitation_status: PropTypes.string,
  created_by: PropTypes.string,
  created_for: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export const meetingPropTypes = PropTypes.shape({
  id: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  callType: PropTypes.string,
  parent_id: PropTypes.string,
  media_core_room_id: PropTypes.integer,
  name: PropTypes.string,
  description: PropTypes.string,
  hash: PropTypes.string,
  meeting_date: PropTypes.string,
  duration: PropTypes.number,
  created_by: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  invitations: PropTypes.arrayOf(invitationPropTypes),
  participants: PropTypes.oneOfType([
    PropTypes.arrayOf(participantPropTypes),
    singleParticipantPropTypes,
    PropTypes.string,
  ]),
});

export const devicePropTypes = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const menuRoutePropTypes = PropTypes.shape({
  path: PropTypes.string,
  label: PropTypes.string,
});

export const matchPropTypes = PropTypes.shape({
  params: PropTypes.shape({
    hash: PropTypes.string,
  }),
});

export const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.string,
]);

export const historyPropTypes = PropTypes.shape({
  push: PropTypes.func,
});

export const contactPropTypes = PropTypes.shape({
  id: PropTypes.string,
  username: PropTypes.string,
  firstname: PropTypes.string,
  middlename: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  status: PropTypes.string,
});

export const groupPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  users: PropTypes.arrayOf(userPropTypes),
});

export const tosDetailsPropTypes = PropTypes.shape({
  version: PropTypes.number,
});

export const dateTimePickerPropTypes = PropTypes.shape({
  dateTime: PropTypes.instanceOf(Date),
  duration: PropTypes.number,
});

export const companyPropTypes = PropTypes.shape({
  logo: PropTypes.string,
  slogan: PropTypes.string,
  main_image: PropTypes.string,
});

export const filePropTypes = PropTypes.shape({
  id: PropTypes.string,
  lastModified: PropTypes.number,
  lastModifiedDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
  webkitRelativePath: PropTypes.string,
});

export const chatMessageFilePropTypes = PropTypes.shape({
  id: PropTypes.string,
  original_name: PropTypes.string,
  preview_file: PropTypes.string,
});

export const messagePropTypes = PropTypes.shape({
  id: PropTypes.string,
  replyCount: PropTypes.number,
  time: PropTypes.string,
  isFlagged: PropTypes.bool,
  isBot: PropTypes.bool,
  isPinned: PropTypes.bool,
  content: PropTypes.string,
  sender: PropTypes.string,
  status: PropTypes.string,
  incoming: PropTypes.bool,
  files: PropTypes.arrayOf(chatMessageFilePropTypes),
});

export const mediaDetectorPropTypes = PropTypes.shape({
  loading: PropTypes.bool,
  loadingDevices: PropTypes.bool,
  loadingStream: PropTypes.bool,
  loadingStreamFailed: PropTypes.bool,
  hasAudio: PropTypes.bool,
  hasVideo: PropTypes.bool,
  stream: PropTypes.object,
  selectedAudioInputDeviceId: PropTypes.string,
  selectedVideoDeviceId: PropTypes.string,
  changeDevices: PropTypes.func,
  stopStream: PropTypes.func,
  reloadStream: PropTypes.func,
});

export const mediaDetectorDefaultProps = PropTypes.shape({
  loading: true,
  loadingDevices: true,
  loadingStream: false,
  loadingStreamFailed: false,
  hasAudio: false,
  hasVideo: false,
  stream: null,
  changeDevices() {},
  stopStream() {},
  reloadStream() {},
});
