import { fork, all } from "redux-saga/effects";
import users from "./users/sagas";
import contacts from "./contacts/sagas";
import usersGroups from "./usersGroups/sagas";
import authSaga from "./auth/sagas";
import meetingSaga from "./meeting/sagas";
import meetingsSaga from "./meetings/sagas";
import messagesSaga from "./messages/sagas";
import permanentMeetingsSaga from "./permanentMeetings/sagas";
import onAirMeetingsSaga from "./onAirMeetings/sagas";
import notificationsSaga from "./notifications/sagas";
import invitationsListSaga from "./invitationsList/sagas";

const sagas = [
  users,
  contacts,
  usersGroups,
  authSaga,
  meetingSaga,
  meetingsSaga,
  messagesSaga,
  permanentMeetingsSaga,
  onAirMeetingsSaga,
  notificationsSaga,
  invitationsListSaga,
];

function* rootSaga() {
  yield all(sagas.map(fork));
}

export default rootSaga;
