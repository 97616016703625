import styled from "styled-components";

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  outline: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.blue.lighten45};
  color: ${({ theme }) => theme.videoStreamTextColor};
  padding-bottom: 190px;
  border-radius: 16px;
  margin-top: 7px;

  video {
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: scaleX(-1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
    border-radius: 14 px;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ gutterBottom }) => gutterBottom || "10px"};
  margin-top: ${({ gutterTop }) => gutterTop || "0px"};
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
