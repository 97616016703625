import React from "react";
import { Loader, LoaderContainer } from "@xyndata/collaboration_ui";

const SuspenseFallback = () => (
  <LoaderContainer fullHeight>
    <Loader />
  </LoaderContainer>
);

export default SuspenseFallback;
