import { takeLatest, call, select } from "redux-saga/effects";
import { store, CONSTANTS } from "@xyndata/communication_app_common";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

export function* fetchPermanentMeetingsSaga(request) {
  const { order, ...params } = yield select(store.permanentMeetings.selectors.selectParams);
  yield call(fetchSaga, {
    api: request,
    onSuccess: store.permanentMeetings.actions.requestSuccess,
    onError: store.permanentMeetings.actions.requestError,
    data: {
      ...params,
      filter: {
        "cr.meeting_type": {
          $op: "or",
          $eq: [CONSTANTS.MEETING_TYPE.PERMANENT, CONSTANTS.MEETING_TYPE.AD_HOC],
        },
      },
    },
  });
}

function* meetingsSaga() {
  yield takeLatest(store.permanentMeetings.actions.requestStart.type, fetchPermanentMeetingsSaga, api.meetings.getAll);
}

export default meetingsSaga;
