import { CONSTANTS } from "@xyndata/communication_app_common";
import * as Sentry from "@sentry/react";
import storage from "./storage";

export const saveAuthToken = (token, browserId, fromToken = false) => {
  storage.setItem(CONSTANTS.STORAGE_KEYS.TOKEN_KEY, token);
  storage.setItem(CONSTANTS.STORAGE_KEYS.BROWSER_ID_KEY, browserId);

  if (fromToken) {
    storage.setItem(CONSTANTS.STORAGE_KEYS.IS_TOKEN_FROM_URL, fromToken);
    Sentry.setTag(CONSTANTS.STORAGE_KEYS.IS_TOKEN_FROM_URL, true);
  } else {
    storage.removeItem(CONSTANTS.STORAGE_KEYS.IS_TOKEN_FROM_URL);
    Sentry.setTag(CONSTANTS.STORAGE_KEYS.IS_TOKEN_FROM_URL, false);
  }

  if (token) {
    storage.setItem(CONSTANTS.STORAGE_KEYS.IS_CARAT_TOKEN_FROM_URL, token);
    Sentry.setTag(CONSTANTS.STORAGE_KEYS.IS_CARAT_TOKEN_FROM_URL, true);
  } else {
    storage.removeItem(CONSTANTS.STORAGE_KEYS.IS_CARAT_TOKEN_FROM_URL);
    Sentry.setTag(CONSTANTS.STORAGE_KEYS.IS_CARAT_TOKEN_FROM_URL, false);
  }
};

export const clearAuthToken = () => {
  storage.removeItem(CONSTANTS.STORAGE_KEYS.TOKEN_KEY);
  storage.removeItem(CONSTANTS.STORAGE_KEYS.BROWSER_ID_KEY);
  storage.removeItem(CONSTANTS.STORAGE_KEYS.IS_TOKEN_FROM_URL);
  Sentry.setTag(CONSTANTS.STORAGE_KEYS.IS_TOKEN_FROM_URL, false);
};

export const getTokenFromStore = () => storage.getItem(CONSTANTS.STORAGE_KEYS.TOKEN_KEY);
export const getIsTokenFromURL = () => storage.getItem(CONSTANTS.STORAGE_KEYS.IS_TOKEN_FROM_URL);
export const getIsCaratTokenFromURL = () => storage.getItem(CONSTANTS.IS_CARAT_TOKEN_FROM_URL);

export const getToken = () => getTokenFromStore();
