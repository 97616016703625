import { takeLatest } from "redux-saga/effects";
import { store } from "@xyndata/communication_app_common";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

export function* fetchUsersGroupsSaga(request) {
  yield fetchSaga({
    api: request,
    onSuccess: store.usersGroups.actions.requestSuccess,
    onError: store.usersGroups.actions.requestError,
  });
}

function* usersGroupsSaga() {
  yield takeLatest(store.usersGroups.actions.requestStart.type, fetchUsersGroupsSaga, api.usersGroups.get);
}

export default usersGroupsSaga;
