import { takeLatest, put, call, select, all } from "redux-saga/effects";
import { store, CONSTANTS } from "@xyndata/communication_app_common";
import api, { request } from "../../api";
import { saveAuthToken, clearAuthToken, getTokenFromStore } from "../../helpers/authToken";
import { getBrowserId } from "../../helpers/browserId";
import getNameSpaceFromUrl from "../../helpers/getNameSpaceFromUrl";
import logger from "../../helpers/logger";
import storage from "../../helpers/storage";
import { supportedLanguages } from "../../components/LanguageSelect/languageSelectConfig";

const { auth, app, tos, usersGroups, users, contacts, notifications } = store;

function* showRequestAlertSaga(alert) {
  yield put(
    store.alerts.actions.addInfoAlert(alert, {
      type: CONSTANTS.EVENTS_TYPES.MEETING_ACCESS_REQUEST,
      payload: { ...alert, toastId: alert.id, access_id: alert.id, meeting_id: alert.conf_room_id },
      persist: true,
    })
  );
}

export function* loadRequestsSaga({ offset = 0, loadedItemsLength = 0 }) {
  const limit = 20;
  try {
    const {
      data: { data, total },
    } = yield call(api.meetings.getMeetingAccess, { limit, offset });

    yield all(data.map((item) => call(showRequestAlertSaga, item)));

    if (total > loadedItemsLength + data.length) {
      // use recursion to load next access requests if total bigger than sum of previously loaded items
      yield call(loadRequestsSaga, {
        offset: loadedItemsLength + limit,
        loadedItemsLength: loadedItemsLength + data.length,
      });
    }
  } catch (error) {
    logger.error(error);
  }
}

export function* loadUserData() {
  try {
    const supportedLanguage =
      supportedLanguages.find((lang) => lang.value === navigator.language?.substring(0, 2))?.value || "en";
    const user = yield select(store.auth.selectors.selectUser);
    const lang = yield call([storage, "getItem"], CONSTANTS.STORAGE_KEYS.LANGUAGE_KEY) || supportedLanguage;

    // set language from DB
    if (user.language) {
      yield put(app.actions.setLanguage(user.language));
      yield call([storage, "setItem"], CONSTANTS.STORAGE_KEYS.LANGUAGE_KEY, user.language);
    } else if (lang) {
      // if language in DB not set check for language in local storage
      yield put(app.actions.setLanguage(lang));
    }

    if (!user.guest) {
      yield put(usersGroups.actions.requestStart());
      yield put(users.actions.requestStart());
      yield put(contacts.actions.requestStart());
      yield put(notifications.actions.requestStart());
      yield call(loadRequestsSaga, { offset: 0, loadedItemsLength: 0 });
    }
  } catch (error) {
    logger.log(error);
  }
}

export function* fetchMeSaga() {
  try {
    const { data } = yield call(request, api.auth.fetchMe);
    // if user authorize and version was updated, or if tos not accepted show tos modal
    const language = yield select(app.selectors.selectLanguage);

    yield put(auth.actions.authorizeSuccess(data));
    yield call(loadUserData);
    const { data: tosData } = yield call(request, api.tos.get, { spaceName: getNameSpaceFromUrl(), language });

    if (tosData.version !== data.tos_version || !data.tos_accepted) {
      yield put(tos.actions.showModal());
    }
  } catch (err) {
    logger.log(err);
    yield put(auth.actions.authorizeError(err?.response?.data?.message));
  }
}

export function* loginSaga(action) {
  const browserId = yield call(getBrowserId);
  try {
    const data = JSON.stringify({
      ...action.payload,
      // eslint-disable-next-line no-useless-escape
      space_name: getNameSpaceFromUrl(),
      browser_id: browserId,
    });
    const response = yield call(request, api.auth.login, data);
    yield call(saveAuthToken, response.data.token, browserId);
    yield call(fetchMeSaga);
  } catch (err) {
    yield put(auth.actions.authorizeError(err?.response?.data?.message));
  }
}

export function* logOutSaga() {
  try {
    yield call(clearAuthToken);
    yield put(auth.actions.successLogout());
  } catch (error) {
    logger.log(error);
  }
}

export function* requestLogOutSaga() {
  try {
    yield call(request, api.auth.logOut);
    yield call(logOutSaga);
  } catch (err) {
    logger.log(err);
    yield put(auth.actions.requestLogoutError(err?.response?.data?.message));
  }
}

export function* appInitSaga() {
  try {
    yield put(app.actions.setLoading(true));

    const company = yield call(request, api.company.get, getNameSpaceFromUrl());

    if (company?.data) {
      yield put(app.actions.setCompany(company.data));
    }

    const token = yield call(getTokenFromStore);
    const isLoading = yield select(auth.selectors.selectAuthLoading);

    // prevent login on guest join page
    if (token && !isLoading && !window.location.pathname.includes("guestjoin")) {
      yield put(auth.actions.requestFetchMe());
    }
  } catch (error) {
    logger.log(error);
  } finally {
    yield put(app.actions.setLoading(false));
  }
}

function* authSaga() {
  yield takeLatest(auth.actions.requestLogin.type, loginSaga);
  yield takeLatest(auth.actions.requestLogout.type, requestLogOutSaga);
  yield takeLatest(auth.actions.forceLogoutAction.type, logOutSaga);
  yield takeLatest(auth.actions.requestFetchMe.type, fetchMeSaga);
  yield takeLatest(app.actions.appInitAction.type, appInitSaga);
}

export default authSaga;
