import { call, select, takeLatest } from "redux-saga/effects";
import { store } from "@xyndata/communication_app_common";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

export function* fetchUsersSaga(request) {
  const params = yield select(store.users.selectors.selectParams);
  yield call(fetchSaga, {
    api: request,
    onSuccess: store.users.actions.requestSuccess,
    onError: store.users.actions.requestError,
    data: params,
  });
}

function* usersSaga() {
  yield takeLatest(store.users.actions.requestStart.type, fetchUsersSaga, api.users.get);
}

export default usersSaga;
