import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { icons as Icons, Typography } from "@xyndata/collaboration_ui";
import { CONSTANTS } from "@xyndata/communication_app_common";
import api from "../../api";
import storage from "../../helpers/storage";
import { MaintenanceBarContainer, Maintenance, CloseButton, ActionButton } from "./MaintenanceBar.styled";

const createMaintenanceItemId = ({ date_from, date_to }) => `${date_from}_${date_to}`;

const getMaintenanceMessagesFromStorage = () => {
  try {
    const hiddenMessages = storage.getItem(CONSTANTS.STORAGE_KEYS.HIDDEN_MAINTENANCES_MESSAGES);
    return JSON.parse(hiddenMessages) || [];
  } catch (error) {
    return [];
  }
};

const MaintenanceBar = () => {
  const intl = useIntl();
  const [messages, setMessages] = useState([]);

  const loadStatus = async () => {
    const { data } = await api.app.getAppStatus();

    if (data.maintenance) {
      const hiddenMessages = getMaintenanceMessagesFromStorage();
      setMessages(data.maintenance.filter((item) => !hiddenMessages.includes(createMaintenanceItemId(item))));
    }
  };

  const onCloseButtonClick = (index) => {
    const hiddenMessages = getMaintenanceMessagesFromStorage();

    storage.setItem(
      CONSTANTS.STORAGE_KEYS.HIDDEN_MAINTENANCES_MESSAGES,
      JSON.stringify([...hiddenMessages, createMaintenanceItemId(messages[index])])
    );

    setMessages(messages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    loadStatus();
  }, []);

  return (
    <MaintenanceBarContainer>
      {messages.length > 0 &&
        messages.map((item, index) => (
          <Maintenance type={item.maintenance_type} key={createMaintenanceItemId(item)}>
            <Typography variant="subtitle1">{item.description}</Typography>

            <ActionButton
              color={item.maintenance_type || "primary"}
              variant="clear"
              onClick={() => onCloseButtonClick(index)}
            >
              {intl.formatMessage({ id: "ui.gotIt" })}
            </ActionButton>

            <CloseButton type="button" onClick={() => onCloseButtonClick(index)}>
              <Icons.CloseIcon />
            </CloseButton>
          </Maintenance>
        ))}
    </MaintenanceBarContainer>
  );
};

export default MaintenanceBar;
