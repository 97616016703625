import { store, CONSTANTS } from "@xyndata/communication_app_common";
import { takeLatest, select, call } from "redux-saga/effects";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

const { invitationsList, auth } = store;

export function* fetchInvitationsListSaga(request) {
  const params = yield select(invitationsList.selectors.selectParams);
  const user = yield select(auth.selectors.selectUser);

  yield call(fetchSaga, {
    api: request,
    onSuccess: invitationsList.actions.requestSuccess,
    onError: invitationsList.actions.requestError,
    data: {
      ...params,
      order: "created_at:desc",
      filter: {
        invitation_status: {
          $op: "and",
          $eq: [CONSTANTS.INVITATION_STATUSES.SENT],
        },
        user_id: {
          $op: "and",
          $eq: user.id,
        },
        meeting_type: {
          $op: "or",
          $eq: [CONSTANTS.MEETING_TYPE.SCHEDULED, CONSTANTS.MEETING_TYPE.AD_HOC, CONSTANTS.MEETING_TYPE.PERMANENT],
        },
      },
    },
  });
}

function* invitationsListSaga() {
  yield takeLatest(invitationsList.actions.requestStart.type, fetchInvitationsListSaga, api.invitation.getAll);
}

export default invitationsListSaga;
