import { call, put } from "redux-saga/effects";
import { request } from "../api";
import logger from "./logger";

export function* fetchSaga({ api, onError, onSuccess, data }) {
  try {
    const response = yield call(request, api, data);
    if (onSuccess) {
      yield put(onSuccess(response.data));
    }
  } catch (err) {
    /* eslint-disable-next-line */
    logger.error(err);
    if (err?.response) {
      yield put(onError(err.response.data.message));
    }
  }
}
