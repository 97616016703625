import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { Loader, Alert } from "@xyndata/collaboration_ui";
import api from "../../api";
import logger from "../../helpers/logger";
import { LoaderWrapper } from "../Profile/components/Media/Media.styled";
import ROUTES from "../../constants/routes";
import { getApiErrorTranslation } from "../../helpers";

const Recording = () => {
  const { meetingId, recordingId } = useParams();
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [sendMessageError, setError] = useState(null);

  const loadRecording = async () => {
    try {
      setLoading(true);
      const response = await api.meetings.getMeetingRecordings({
        meetingId,
      });

      logger.log(response);

      const { data } = await api.meetings.getMeetingRecordingById({
        meetingId,
        recordingId,
      });

      if (data) {
        const blob = new Blob([data], {
          type: "application/octet-stream",
        });

        logger.log(data);

        saveAs(blob, data.original_filename || "recording.mjr");
        window.close();

        history.push(ROUTES.HOME);
      } else {
        setError(intl.formatMessage({ id: "chat.downloadingError" }));
      }
    } catch (err) {
      logger.error(err);
      setError(getApiErrorTranslation(intl, err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRecording();
  }, []);

  return (
    <LoaderWrapper>
      {sendMessageError && <Alert>{sendMessageError}</Alert>}
      {!sendMessageError && loading && <Loader />}
    </LoaderWrapper>
  );
};

export default Recording;
