import React from "react";
import PropTypes from "prop-types";
import { LanguageSelect } from "@xyndata/collaboration_ui";
import { store, CONSTANTS } from "@xyndata/communication_app_common";
import { connect } from "react-redux";
import { supportedLanguages } from "./languageSelectConfig";
import storage from "../../helpers/storage";
import api from "../../api";
import { getIsTokenFromURL } from "../../helpers/authToken";
import { dispatchToBridge } from "../../helpers/delphiBridge";

const LanguageSelectComponent = ({ language, onLanguageChange, authorized, ...props }) => {
  const onChangeLanguage = async (lang) => {
    onLanguageChange(lang.value);
    storage.setItem(CONSTANTS.STORAGE_KEYS.LANGUAGE_KEY, lang.value);

    if (authorized) {
      await api.auth.updateUser({
        data: {
          language: lang.value,
        },
      });
    }

    if (getIsTokenFromURL()) {
      dispatchToBridge({
        type: "LANGUAGE_CHANGED",
        payload: {
          language: lang.value,
        },
      });
    }
  };

  return (
    <LanguageSelect
      onChange={onChangeLanguage}
      options={supportedLanguages}
      selectedOption={supportedLanguages.find((lang) => lang.value === language)}
      closeMenuOnSelect
      {...props}
    />
  );
};

LanguageSelectComponent.propTypes = {
  language: PropTypes.string.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  authorized: PropTypes.bool,
};

LanguageSelectComponent.defaultProps = {
  authorized: true,
};

const mapDispatchToProps = {
  onLanguageChange: store.app.actions.setLanguage,
};

const mapStateToProps = (state) => ({
  language: store.app.selectors.selectLanguage(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectComponent);
