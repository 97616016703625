export const getFullName = (user) =>
  (user.lastname && user.firstname
    ? `${user.lastname} ${user.firstname}`
    : user.lastname || user.firstname || ""
  ).trim();

export const getUserLabel = (user = {}) => {
  return getFullName(user) || user.name || user.email || "";
};

const getUserFirstName = (user = {}) => {
  const { firstname, name, email } = user;
  return firstname || name || email || "";
};

export const getUsers = (
  members = [],
  users,
  moderators,
  groups = [],
  guestEmails = [],
  guests = [],
  meetingInvitations = []
) => {
  return [
    ...members.map((memberId) => ({
      ...users[memberId],
      label: getUserLabel(users[memberId]),
      firstname: getUserFirstName(users[memberId]),
      lastname: users?.[memberId]?.lastname || "",
      moderator: moderators.includes(memberId),
      isGuest: guests && guests.includes(memberId),
      invitationStatus:
        meetingInvitations.find(
          (invitation) => invitation.created_for === memberId || invitation.created_by === memberId
        )?.invitation_status || null,
    })),
    ...groups.map((groupId) => groupId && { groupId, label: getUserLabel(users[groupId]) }),
    ...guestEmails.map(
      (email) =>
        guestEmails && {
          isGuest: true,
          label: email,
          firstname: email,
        }
    ),
  ];
};
