import { put, call } from "redux-saga/effects";

import { createApi as createApiClient, store } from "@xyndata/communication_app_common";
import config from "../config";
import { getToken, clearAuthToken } from "../helpers/authToken";

const { auth, tos } = store;

export function* request(api, data) {
  try {
    const response = yield call(api, data);
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      yield call(clearAuthToken);
      yield put(auth.actions.successLogout());
      if (error?.response?.config?.url !== "user/login") {
        // just reload the page on 401 error if it not login
        yield call((...args) => window.location.reload(...args));
      }
    }

    if (error?.response?.status === 451) {
      yield put(tos.actions.showModal(true));
    }

    throw error;
  }
}

const apiClient = createApiClient({
  baseURL: config.apiProxy.proxyPath,
  getToken,
  requestTimeout: 30 * 1000,
  onError: async (error) => {
    if (error?.response?.status === 401) {
      clearAuthToken();
      // just reload the page on 401 error if it not login
      if (error.response.config.url !== "user/login") {
        window.location.reload();
      }
    }
  },
});

export default apiClient;
