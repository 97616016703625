import styled from "styled-components/macro";
import { Typography } from "@xyndata/collaboration_ui";

export const ErrorScreenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  height: 100vh;

  a {
    width: auto;
    text-decoration: none;
    display: inline-flex;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 520px;
  max-width: 80%;

  @media ${({ theme }) => theme.devices.min.tablet} {
    max-width: 500px;
  }
`;

export const Title = styled(Typography)`
  font-size: 48px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
