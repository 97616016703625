export const { PUBLIC_URL } = process.env;
export const SHOW_COPY_DONE_DURATION = 1000;
export const VIDEO_ASPECT_RATIO = 0.75142;

export const MEETING_LAYOUTS = {
  GRID: "GRID",
  RIGHT_BAR: "RIGHT_BAR",
  BOTTOM_BAR: "BOTTOM_BAR",
};

export const SCROLL_OFFSET = 20;

export const CONTACTS_VIEWS = {
  COLUMN: "COLUMN",
  GRID: "GRID",
};

export const BITRATE_CONFIG = {
  SLOW: 66000,
  MEDIUM: 132000,
};
