import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import * as Sentry from "@sentry/react";
import { childrenPropTypes } from "../../constants/propTypes";
import { ButtonsContainer, StyledButton } from "./ErrorBoundary.styled";
import ErrorScreen from "../ErrorScreen/ErrorScreen";

const reload = () => {
  // reload should be bind to window.location to prevent Illegal invocation error
  setTimeout(window.location.reload.bind(window.location), 0);
};

const Footer = ({ intl, onReportFeedbackClick }) => {
  return (
    <ButtonsContainer>
      <StyledButton onClick={onReportFeedbackClick}>
        {intl.formatMessage({ id: "errorBoundary.reportFeedback" })}
      </StyledButton>
      <StyledButton onClick={reload}>{intl.formatMessage({ id: "errorBoundary.reloadPage" })}</StyledButton>
    </ButtonsContainer>
  );
};

Footer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  onReportFeedbackClick: PropTypes.func.isRequired,
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: Math.random().toString(36).substr(2, 10),
      hasError: false,
      title: props.intl.formatMessage({ id: "errorBoundary.alertTitle" }),
      subtitle: "",
    };
  }

  static getDerivedStateFromError(errorInfo) {
    return { hasError: true, errorInfo };
  }

  componentDidCatch(error, errorInfo) {
    const { intl } = this.props;
    // eslint-disable-next-line no-console
    console.log(`ErrorBoundary error`, error, JSON.stringify(errorInfo));

    if (error?.name === "ChunkLoadError") {
      this.setState({
        title: intl.formatMessage({ id: "ui.chunkErrorTitle" }),
        subtitle: intl.formatMessage({ id: "ui.chunkErrorSubTitle" }),
      });
    }

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  showReportDialog = () => {
    const { eventId } = this.state;
    const { intl } = this.props;
    const config = {
      eventId,
      title: intl.formatMessage({ id: "userFeedback.title" }),
      subtitle: intl.formatMessage({ id: "userFeedback.subtitle" }),
      subtitle2: intl.formatMessage({ id: "userFeedback.subtitle2" }),
      labelName: intl.formatMessage({ id: "userFeedback.labelName" }),
      labelEmail: intl.formatMessage({ id: "userFeedback.labelEmail" }),
      labelComments: intl.formatMessage({ id: "userFeedback.labelComments" }),
      labelClose: intl.formatMessage({ id: "userFeedback.labelClose" }),
      labelSubmit: intl.formatMessage({ id: "userFeedback.labelSubmit" }),
      errorGeneric: intl.formatMessage({ id: "userFeedback.errorGeneric" }),
      errorFormEntry: intl.formatMessage({ id: "userFeedback.errorFormEntry" }),
      successMessage: intl.formatMessage({ id: "userFeedback.successMessage" }),
    };
    Sentry.showReportDialog(config);
  };

  render() {
    const { hasError, title, subtitle } = this.state;
    const { children, intl } = this.props;

    if (hasError) {
      return (
        <ErrorScreen
          title={title}
          subtitle={subtitle}
          footer={<Footer onReportFeedbackClick={this.showReportDialog} intl={intl} />}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: childrenPropTypes.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(ErrorBoundary);
