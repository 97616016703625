import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from "@xyndata/communication_app_common";
import api from "../../api";
import logger from "../../helpers/logger";

const intervalCallback = async () => {
  try {
    await api.auth.ping();
  } catch (err) {
    logger.log(`PingApi error`, err);
  }
};

const PingApi = ({ isAuthorized }) => {
  // Ping API every 15 minutes if logged in to keep session alive
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isAuthorized) {
      const interval = setInterval(() => {
        intervalCallback();
      }, Math.floor(15 * 60000));
      return () => clearInterval(interval);
    }
  }, [isAuthorized]);
  return null;
};

PingApi.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthorized: store.auth.selectors.selectIsAuthorized(state),
});

export default connect(mapStateToProps, null)(PingApi);
