if (!["production", "test-api"].includes(process.env.NODE_ENV)) {
  require("dotenv").config(); // eslint-disable-line
}

module.exports = {
  developmentMode: process.env.NODE_ENV !== "production",
  app: {
    port: process.env.PORT || 3001,
    interface: process.INTERFACE || "0.0.0.0",
  },
  amqp: {
    connection: process.env.RABBITMQ_HOST
      ? `amqp://${process.env.RABBITMQ_USERNAME}:${process.env.RABBITMQ_PASSWORD}@${process.env.RABBITMQ_HOST}:${process.env.RABBITMQ_PORT}`
      : "amqp://rabbitmq:rabbitmq@localhost:5672",
    exchangeName: "communication_be",
    exchangeType: "direct",
    apiToBff: {
      queuePrefix: "api_to_bff_",
      routingKey: "api_to_bff",
    },
    bffToApi: {
      queuePrefix: "bff_to_api_",
      routingKey: "bff_to_api",
    },
    logging: true,
  },
  ws: {
    path: "/_ws",
    logging: true,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DNS,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  },
  env: "dev",
  apiProxy: {
    target: process.env.REACT_APP_API_BASE_URL || "http://localhost:3000/",
    proxyPath: process.env.REACT_APP_API_MOUNT_PATH || "/_api",
    api: "api/v1",
  },
};
