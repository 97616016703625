import io from "socket.io-client";
import { store } from "@xyndata/communication_app_common";
import logWsEvent from "../helpers/logWsEvent";
import config from "../config";
import { getToken } from "../helpers/authToken";
import { getBrowserId } from "../helpers/browserId";

const { auth, app, meeting } = store;
const wsServer = config.developmentMode ? "ws://localhost:3002" : "";
let socket = null;

function wsMiddleware({ dispatch, getState }) {
  const initWs = () => {
    socket = io(wsServer, {
      path: config.ws.path,
      secure: true,
      // websocket transport connection to BFF does not emit "disconnect" event
      transports: config.developmentMode ? ["polling"] : ["polling", "websocket"],
    });

    socket.on("connect", () => {
      // connect event includes also re-connection, no need to handle it separatelly
      logWsEvent("connect", socket);

      const state = getState();
      if (state.auth.user?.id) {
        const token = getToken();
        socket.emit("auth", {
          jwt: token,
          userId: state.auth.user.id,
          companyId: state.auth.user.company_id,
          browserId: getBrowserId(),
        });
      }
    });

    socket.on("event", (event) => {
      logWsEvent("event", event);
      if (event.type) {
        // convert ws event to action
        dispatch({
          type: event.type,
          visible: !!event.visible,
          payload: { ...event.payload, created_at: event.created_at },
        });
      }
    });

    socket.on("disconnect", (event) => {
      logWsEvent("WS disconnect", event);

      const isCaratNativeApp = window?.location?.search
        ? new URLSearchParams(window.location.search)?.get("injectScreenShare") === "true"
        : false;

      console.log(
        `isCaratNativeApp`,
        isCaratNativeApp,
        new URLSearchParams(window.location.search)?.get("injectScreenShare")
      );
      if (window.location.pathname.includes("videoroom") && !isCaratNativeApp) {
        if (navigator.onLine) {
          dispatch(meeting.actions.hardMeetingUpdate({ meetingDisconnected: true }));
        } else {
          dispatch(meeting.actions.hardMeetingUpdate({ meetingOffline: true }));
        }
      }
    });

    socket.io.on("reconnect_attempt", (event) => {
      logWsEvent("WS reconnect_attempt", event);
    });

    socket.io.on("reconnect_error", (event) => {
      logWsEvent("WS reconnect_error", event);
    });

    socket.io.on("reconnect_failed", (event) => {
      logWsEvent("WS reconnect_failed", event);
    });
  };

  return (next) => (action) => {
    // PREVENT ERRORS IN TESTS
    if (process.env.TEST_MODE) {
      return next(action);
    }

    if (action.type === app.actions.appInitAction.type) {
      initWs(dispatch);
      return next(action);
    }

    if (action.type === auth.actions.authorizeSuccess.type) {
      const token = getToken();
      const { id: userId, company_id: companyId } = action.payload;
      socket.emit("auth", { jwt: token, userId, companyId, browserId: getBrowserId() });
      return next(action);
    }

    if (action.type === auth.actions.successLogout.type || action.type === auth.actions.forceLogoutAction.type) {
      socket.emit("logout");
      return next(action);
    }

    if (action.type === meeting.actions.joinMeeting.type) {
      socket.emit("meeting/join", { meeting_id: action.payload });
    }

    if (action.type === meeting.actions.setAttendeeId.type) {
      socket.emit("meeting/setAttendeeId", { attendee_id: action.payload });
    }

    if (action.type === meeting.actions.leaveMeeting.type) {
      socket.emit("meeting/leave", { meeting_id: action.payload });
    }

    return next(action);
  };
}

export default wsMiddleware;
