import React, { useEffect } from "react";
import { Typography } from "@xyndata/collaboration_ui";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import logger from "../../helpers/logger";
import { Container, ErrorScreenWrapper, Title, Row } from "./ErrorScreen.styled";

const ErrorScreen = ({ title, subtitle, footer }) => {
  useEffect(() => {
    if (logger.hasBuffer()) {
      Sentry.withScope((scope) => {
        scope.setExtra("logger", logger.getBuffer());
        Sentry.captureMessage("Videoroom logger buffer");
      });
    }
  }, []);

  return (
    <ErrorScreenWrapper>
      <Container>
        <Title data-test="error-screen-title" color="gray" gutterBottom={23} textAlign="left">
          {title}
        </Title>
        {subtitle && (
          <Typography
            data-test="error-screen-subtitle"
            variant="body1"
            color="gray.darken10"
            gutterBottom={32}
            textAlign="left"
          >
            {subtitle}
          </Typography>
        )}
        {footer && <Row>{footer}</Row>}
      </Container>
    </ErrorScreenWrapper>
  );
};

ErrorScreen.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
};

ErrorScreen.defaultProps = {
  subtitle: "",
  footer: null,
};

export default ErrorScreen;
