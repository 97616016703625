import { takeLatest, select, call } from "redux-saga/effects";
import { store } from "@xyndata/communication_app_common";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

export function* fetchContactsSaga(request) {
  const params = yield select(store.contacts.selectors.selectParams);
  yield call(fetchSaga, {
    api: request,
    onSuccess: store.contacts.actions.requestSuccess,
    onError: store.contacts.actions.requestError,
    data: params,
  });
}

function* contactsSaga() {
  yield takeLatest(store.contacts.actions.requestStart.type, fetchContactsSaga, api.contacts.getAll);
}

export default contactsSaga;
