import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  height: auto;
  flex-direction: column;
  border-radius: 24px;
  padding: 8px 24px 32px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 800px;
  min-width: 480px;
`;
export const SelectWrapper = styled.div`
  display: flex;
  width: 120px;
  align-self: flex-end;
`;
