import React, { Suspense, lazy, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Switch, Route, useLocation, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { useIntl } from "react-intl";
import { store, CONSTANTS, createApi as createApiClient } from "@xyndata/communication_app_common";
import { ThemeProvider } from "styled-components";
import api from "./api";
import ROUTES from "./constants/routes";
import PingApi from "./components/PingApi";
import SuspenseFallback from "./components/SuspenseFallback/SuspenseFallback";
import ErrorScreen from "./components/ErrorScreen";
import theme from "./styles/themes/default";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalStyle from "./styles/globalStyle";
import { clearAuthToken, saveAuthToken } from "./helpers/authToken";
import logger, { intSentry } from "./helpers/logger";
import config from "./config";
import { userPropTypes } from "./constants/propTypes";
import GuestJoinIframe from "./pages/GuestJoinIframe";
import MaintenanceBar from "./components/MaintenanceBar";
import Recording from "./pages/Recording";

const Home = lazy(() => import("./pages/Home"));
const Page404 = lazy(() => import("./pages/404"));
const DeviceTest = lazy(() => import("./pages/DeviceTest"));
const Notifier = lazy(() => import("./components/Notifier"));
const BrowserNotSupported = lazy(() => import("./components/BrowserNotSupported"));
const ProtectedRoute = lazy(() => import("./components/ProtectedRoute"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPassword"));
const TosModal = lazy(() => import("./pages/Home/components/Modals/TermsOfServiceModal"));
const InvitationNotifier = lazy(() => import("./components/InvitationNotifier"));
const JoinGuest = lazy(() => import("./pages/JoinGuest"));
const UserInvitation = lazy(() => import("./pages/UserInvitation"));
const MainLayout = lazy(() => import("./components/MainLayout"));
const VideoRoomHeadless = lazy(() => import("./pages/VideoRoom/headless"));
const VideoRoom = lazy(() => import("./pages/VideoRoom"));

const App = ({ fetchMe, user }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname = "" } = useLocation();
  const { push, replace } = useHistory();
  const company = useSelector(store.app.selectors.selectCompany);
  const [initialized, setInitialized] = useState(false);
  const [initializationError, setInitializationError] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tokenFromUrl = params.get("authToken");

  // Redirect for old links that include "/meet"
  const makeMeetRedirect = pathname === "/meet" || pathname.includes(`/meet/`);
  const companyTheme = { ...theme, ...company };

  const authUserByToken = async () => {
    setAuthLoading(true);

    try {
      const browserId = params.get("browserId");
      // create separate api client to prevent api call before user login
      const apiClient = createApiClient({
        baseURL: config.apiProxy.proxyPath,
        getToken: () => tokenFromUrl,
        onError: async (err) => {
          logger.error(err);
          clearAuthToken();
          push(ROUTES.HOME);
        },
      });
      // remove token from URL and test is token valid
      const isTokenFromURL = false;
      saveAuthToken(tokenFromUrl, browserId, isTokenFromURL);

      await apiClient.auth.ping();

      fetchMe();

      params.delete("browserId");
      params.delete("authToken");

      replace({ search: params.toString() });
    } catch (err) {
      logger.error(err);
      clearAuthToken();
    } finally {
      setAuthLoading(false);
    }
  };

  const loadBulk = async () => {
    setInitializationError(null);
    try {
      const { data } = await api.app.getBulk();
      dispatch({ type: CONSTANTS.EVENTS_TYPES.APP_BULK, payload: data });
      config.env = data.env;
      config.sentry.dsn = data.sentry;
      if (data.sentry) {
        intSentry();
      }

      if (tokenFromUrl) {
        await authUserByToken();
      }
      setInitialized(true);
    } catch (err) {
      setInitializationError(err);
    }
  };

  useEffect(() => {
    loadBulk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPage = (tokenFromUrl && user) || !tokenFromUrl;

  return (
    <ThemeProvider theme={companyTheme}>
      <ErrorBoundary>
        {!authLoading && (
          <Suspense fallback={<SuspenseFallback />}>
            {makeMeetRedirect && <Redirect to={pathname.replace("/meet", "")} />}
            <BrowserNotSupported />
            <MaintenanceBar />
            {initializationError && (
              <ErrorScreen
                title={intl.formatMessage({ id: "app.initializationFailedTitle" })}
                subtitle={intl.formatMessage({ id: "app.initializationFailedSubtitle" })}
              />
            )}
            {initialized && showPage && (
              <Switch>
                <Route
                  exact
                  path={[ROUTES.HOME, ROUTES.GUEST_JOIN, ROUTES.HOME_REDIRECT, ROUTES.DECLINE_INVITE]}
                  component={Home}
                />
                <Route exact path={ROUTES.GUEST_JOIN_PARAMS} component={JoinGuest} />
                <Route exact path={ROUTES.GUEST_INVITATION} component={JoinGuest} />
                <Route exact path={ROUTES.USER_INVITATION} component={UserInvitation} />
                <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
                <Route exact path={ROUTES.VIDEOROOM_HEADLESS} component={VideoRoomHeadless} />
                <Route exact path={ROUTES.VIDEOROOM} component={VideoRoom} />
                <ProtectedRoute
                  exact
                  path={[ROUTES.MEETINGS, ROUTES.CONTACTS, ROUTES.PROFILE]}
                  component={MainLayout}
                />
                <Route exact path={ROUTES.DEVICE_TEST} component={DeviceTest} />
                <Route exact path={ROUTES.GUEST_JOIN_IFRAME} component={GuestJoinIframe} />
                <ProtectedRoute exact path={ROUTES.RECORDING} component={Recording} />
                <Route component={Page404} />
              </Switch>
            )}
            <Notifier />
            <TosModal />
            <InvitationNotifier />
            <PingApi />
          </Suspense>
        )}
      </ErrorBoundary>
      <GlobalStyle />
    </ThemeProvider>
  );
};

App.propTypes = {
  fetchMe: PropTypes.func.isRequired,
  user: userPropTypes,
};

App.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: store.auth.selectors.selectUser(state),
});

const mapDispatchToProps = {
  fetchMe: store.auth.actions.requestFetchMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
