/* eslint no-console:0 */
// import config from "../config";
import logger from "./logger";

export default (type, event) => {
  // if (!config.developmentMode) return;

  const titleArgs = [`%c WS event: ${type}`, "color: blue"];
  logger.group(...titleArgs);
  logger.log(event);
  logger.groupEnd(...titleArgs);
};
