/* global Janus */
/* eslint no-console:0 */
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import config from "../config";
import storage from "./storage";

let enabled = !!storage.getItem("logging") || false;
// const isEnabled = () => enabled; // config.developmentMode || enabled;
const isEnabled = () => {
  console.log(enabled);
  return true;
}; // config.developmentMode || enabled;

window.enableLogger = () => {
  enabled = true;
  storage.setItem("logging", "1");
};

window.disableLogger = () => {
  enabled = false;
  storage.removeItem("logging");
};

const BUFFER_SIZE = 50;
const buffer = [];
const bufferIgnore = ["group", "groupCollapsed", "groupEnd"];

const logger = ["log", "warn", "error", "group", "groupCollapsed", "groupEnd"].reduce((acc, curr) => {
  /* eslint-disable-next-line */
  acc[curr] = (...args) => {
    if (curr === "error") {
      Sentry.captureException(args[0]);
    }
    if (isEnabled()) {
      console[curr](...args, new Date().toLocaleString());
    }

    if (!bufferIgnore.includes(curr)) {
      if (buffer.length + 1 > BUFFER_SIZE) {
        buffer.shift();
      }
      buffer.push({ level: curr, ...args });
    }
  };

  return acc;
}, {});

Janus.trace = logger.log.bind(logger);
Janus.debug = logger.log.bind(logger);
Janus.vdebug = logger.log.bind(logger);
Janus.log = logger.log.bind(logger);
Janus.warn = logger.log.bind(logger);
Janus.error = logger.log.bind(logger);

export const intSentry = () => {
  if (config.sentry.dsn && !config.developmentMode) {
    logger.log("intSentry", config, process.env);
    Sentry.init({
      dsn: config.sentry.dsn,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      normalizeDepth: 4,
      environment: config.env || "dev",
      release: config.sentry.release,
      ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
        "Request failed with status code 401",
      ],
    });
  }
};

export default {
  isEnabled,
  hasBuffer() {
    return buffer.length > 0;
  },
  getBuffer() {
    return buffer;
  },
  ...logger,
};
