import styled from "styled-components";

const View = styled.div`
  display: ${({ display = "flex" }) => display};
  flex-direction: ${({ flexDirection = "column" }) => flexDirection};
  align-items: ${({ alignItems = "flex-start" }) => alignItems};
  align-content: ${({ alignContent = "center" }) => alignContent};
  justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
  justify-items: ${({ justifyItems = "flex-start" }) => justifyItems};
  flex-wrap: ${({ flexWrap = "wrap" }) => flexWrap};

  width: ${({ width = "auto" }) => width};
  height: ${({ height = "auto" }) => height};
  min-height: ${({ minHeight = "auto" }) => minHeight};

  margin: ${({ m = 0 }) => m};
  ${({ mt }) => mt && `margin-top:${mt}`};
  ${({ mb }) => mb && `margin-bottom:${mb}`};
  ${({ ml }) => ml && `margin-left:${ml}`};
  ${({ mr }) => mr && `margin-right:${mr}`};

  padding: ${({ p = 0 }) => p};
  ${({ pt }) => pt && `padding-top:${pt}`};
  ${({ pb }) => pb && `padding-bottom:${pb}`};
  ${({ pl }) => pl && `padding-left:${pl}`};
  ${({ pr }) => pr && `padding-right:${pr}`};
`;

export default View;
