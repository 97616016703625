import { store, CONSTANTS } from "@xyndata/communication_app_common";
import { supportedLanguages } from "../components/LanguageSelect/languageSelectConfig";
import createStore from "./store";
import config from "../config";
import storage from "../helpers/storage";

const rootStore = createStore({});

rootStore.dispatch(store.app.actions.appInitAction());

const supportedLanguage = supportedLanguages.find((lang) => lang.value === navigator.language?.substring(0, 2)) || "en";

const lang = storage.getItem(CONSTANTS.STORAGE_KEYS.LANGUAGE_KEY) || supportedLanguage.value;

if (lang) {
  rootStore.dispatch(store.app.actions.setLanguage(lang));
}

if (config.developmentMode) {
  window["store"] = rootStore; // eslint-disable-line
}

export default rootStore;
