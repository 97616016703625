import { takeLatest, call, select } from "redux-saga/effects";
import { store } from "@xyndata/communication_app_common";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";

export function* fetchOnAirMeetingsSaga(request) {
  const { order, ...params } = yield select(store.onAirMeetings.selectors.selectParams);
  yield call(fetchSaga, {
    api: request,
    onSuccess: store.onAirMeetings.actions.requestSuccess,
    onError: store.onAirMeetings.actions.requestError,
    data: {
      ...params,
    },
  });
}

function* meetingsSaga() {
  yield takeLatest(
    store.onAirMeetings.actions.requestStart.type,
    fetchOnAirMeetingsSaga,
    api.meetings.getOnAirMeetings
  );
}

export default meetingsSaga;
