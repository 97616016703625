import { takeLatest } from "redux-saga/effects";
import { store } from "@xyndata/communication_app_common";
import { fetchSaga } from "../../helpers/reduxHelpers";
import api from "../../api";

export function* fetchMessagesSaga(request, { payload }) {
  yield fetchSaga({
    api: request,
    data: payload,
    onSuccess: store.messages.actions.requestSuccess,
    onError: store.messages.actions.requestError,
  });
}

function* messagesSaga() {
  yield takeLatest(store.messages.actions.requestStart.type, fetchMessagesSaga, api.messages.getAll);
}

export default messagesSaga;
