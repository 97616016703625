import { createIntl, createIntlCache } from "react-intl";
import { store, CONSTANTS } from "@xyndata/communication_app_common";
import config from "../config";
import translations from "../translations";
import api from "../api";
import { getUserLabel } from "../helpers/user";
import logger from "../helpers/logger";
import { getBrowserId } from "../helpers/browserId";

const failedPostfix = new RegExp("_FAILED$");

const {
  auth,
  alerts,
  messages,
  meeting: meetingRedux,
  app,
  invitationsList,
  invitations,
  permanentMeetings,
  meetings,
  notifications,
  users: usersRedux,
  onAirMeetings,
} = store;

const loadMeeting = async (id) => {
  try {
    const response = await api.meetings.getById(id);
    return response.data;
  } catch (err) {
    logger.log(err);
    return null;
  }
};

export const isRequestAlreadyExist = (alertsMessages, request) =>
  alertsMessages.some(
    (alert) => alert?.payload?.meeting_id === request.meeting_id && alert?.payload?.user_id === request.user_id
  );

// TODO: move middleware to sagas
const loadUsers = async () => {
  try {
    const response = await api.users.get();
    return response.data;
  } catch (err) {
    return null;
  }
};

const alertMiddleware = ({ dispatch, getState }) => {
  return (next) => async (action) => {
    const state = getState();
    const locale = app.selectors.selectLanguage(state);
    const cache = createIntlCache();
    const intl = createIntl({ locale, messages: translations[locale] }, cache);

    switch (action.type) {
      case CONSTANTS.EVENTS_TYPES.MEETING_INVITE_ACCEPT: {
        const { meeting_id, created_for, invitation_id } = action.payload;
        const users = await loadUsers();
        // update users to have all users with correct nickname
        dispatch(usersRedux.actions.requestSuccess(users));
        const meeting = await loadMeeting(meeting_id);
        const invitedUser = users.data.find(({ id }) => id === created_for);
        const currentUser = auth.selectors.selectUser(state);

        // don't show notification for invited user
        if (currentUser?.id !== created_for && invitedUser && meeting_id === meeting?.id) {
          dispatch(
            alerts.actions.addSuccessAlert(
              intl.formatMessage(
                { id: "notification.invitationAccepted" },
                { userName: getUserLabel(invitedUser), meetingName: meeting.name }
              )
            )
          );
        }

        if (currentUser?.id === created_for) {
          dispatch(invitations.actions.removeInvitation(invitation_id));
        }

        break;
      }
      case CONSTANTS.EVENTS_TYPES.MEETING_INVITE_DECLINE: {
        const { visible } = action;
        const { meeting_id, created_for, invitation_id } = action.payload;
        const users = await loadUsers();
        // update users to have all users with correct nickname
        dispatch(usersRedux.actions.requestSuccess(users));
        const invitedUser = users.data.find(({ id }) => id === created_for);
        const currentUser = auth.selectors.selectUser(state);

        if (currentUser?.id === created_for) {
          dispatch(invitations.actions.removeInvitation(invitation_id));
          dispatch({ type: CONSTANTS.EVENTS_TYPES.MEETING_TERMINATED, payload: { meeting_id } });
        } else if (currentUser && currentUser.id !== created_for && invitedUser) {
          const meeting = await loadMeeting(meeting_id);

          if (visible && meeting) {
            dispatch(
              alerts.actions.addWarningAlert(
                intl.formatMessage(
                  { id: "notification.invitationDeclined" },

                  { userName: getUserLabel(invitedUser), meetingName: meeting.name }
                )
              )
            );
          }
        }

        break;
      }
      case CONSTANTS.EVENTS_TYPES.MEETING_USER_REMOVED: {
        const { meeting_id, user_id, browser_identity } = action.payload;
        const user = auth.selectors.selectUser(state);
        const meeting = meetingRedux.selectors.selectMeeting(state);
        const browserId = getBrowserId();

        if (user.id === user_id && browser_identity === browserId) {
          dispatch(permanentMeetings.actions.requestStart());
          dispatch(meetings.actions.requestStart());
          dispatch(onAirMeetings.actions.requestStart());
          dispatch(invitationsList.actions.requestStart());

          if (meeting_id === meeting.id) {
            dispatch(meetingRedux.actions.userWasRemoved(meeting.id));
          }
        }

        break;
      }
      // TODO: move it to separate middleware
      case CONSTANTS.EVENTS_TYPES.MEETING_INVITE_SENT: {
        if (
          action.payload.meeting_type === CONSTANTS.MEETING_TYPE.AD_HOC ||
          action.payload.meeting_type === CONSTANTS.MEETING_TYPE.SCHEDULED ||
          action.payload.meeting_type === CONSTANTS.MEETING_TYPE.PERMANENT
        ) {
          dispatch(permanentMeetings.actions.requestStart());
          dispatch(meetings.actions.requestStart());
          dispatch(onAirMeetings.actions.requestStart());
          dispatch(invitationsList.actions.requestStart());
        }
        break;
      }
      case CONSTANTS.EVENTS_TYPES.MESSAGE_SENT: {
        const user = auth.selectors.selectUser(state);
        const sender = usersRedux.selectors.selectUserById(state, action.payload.created_by);
        const isChatVisible = meetingRedux.selectors.selectIsMeetingChatVisible(state);
        const isCurrentUserMessage = action.payload.created_by === user.id;
        const meeting = meetingRedux.selectors.selectMeeting(state);

        if (action.payload.meeting_id === meeting.id) {
          dispatch(messages.actions.addMessage(action.payload));

          if (action.payload.visible && !isChatVisible && !isCurrentUserMessage) {
            dispatch(
              alerts.actions.addInfoAlert(
                intl.formatMessage({ id: "meetings.newMessageFrom" }, { user: getUserLabel(sender) })
              )
            );
          }
        }

        break;
      }
      case CONSTANTS.EVENTS_TYPES.NOTIFICATION_UPDATE: {
        dispatch(notifications.actions.requestStart());
        break;
      }
      case CONSTANTS.EVENTS_TYPES.MEETING_ACCESS_REQUEST: {
        const { payload } = action;
        const alertsMessages = alerts.selectors.selectAlerts(state);

        // prevent request duplicates
        if (!isRequestAlreadyExist(alertsMessages, payload)) {
          dispatch(
            alerts.actions.addInfoAlert("", {
              ...action,
              unique: true,
              autoClose: false,
              toastId: action.payload.access_id,
            })
          );
        }
        break;
      }

      // TODO: move it to meeting reducer
      case CONSTANTS.EVENTS_TYPES.MEETING_ACCESS_DECLINED: {
        dispatch(meetingRedux.actions.updateMeeting({ id: action.payload.meeting_id, guestJoinDeclined: true }));
        break;
      }
      default:
        if (action.type !== "LOGIN_FAILED" && failedPostfix.test(action.type)) {
          dispatch(
            alerts.actions.addErrorAlert(
              config.developmentMode ? `App error (${action.type}): ${action.error}` : action.error,
              {
                persist: true,
              }
            )
          );
        }
        break;
    }

    return next(action);
  };
};

export default alertMiddleware;
