import * as React from "react";

const deFlag = (props) => (
  <svg width={34} height={30} viewBox="0 0 34 30" fill="none" {...props}>
    <g filter="url(#prefix__filter0_ddd_de)">
      <g clipPath="url(#prefix__clip0_de)">
        <path d="M5 17.334h24V24H5v-6.666z" fill="#FFCE00" />
        <path d="M5 4h24v6.667H5V4z" fill="#000" />
        <path d="M5 10.666h24v6.667H5v-6.666z" fill="#D00" />
      </g>
    </g>
    <defs>
      <clipPath id="prefix__clip0_de">
        <path d="M5 8a4 4 0 014-4h16a4 4 0 014 4v12a4 4 0 01-4 4H9a4 4 0 01-4-4V8z" fill="#fff" />
      </clipPath>
      <filter
        id="prefix__filter0_ddd_de"
        x={0}
        y={0}
        width={34}
        height={30}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2.5} />
        <feColorMatrix values="0 0 0 0 0.368627 0 0 0 0 0.443137 0 0 0 0 0.54902 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0.368627 0 0 0 0 0.443137 0 0 0 0 0.54902 0 0 0 0.14 0" />
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0.368627 0 0 0 0 0.443137 0 0 0 0 0.54902 0 0 0 0.2 0" />
        <feBlend in2="effect2_dropShadow" result="effect3_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default deFlag;
