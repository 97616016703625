import en from "./en.json";
import de from "./de.json";
// import cn from "./cn.json";
// import cz from "./cz.json";
// import pl from "./pl.json";
// import pt from "./pt.json";
// import sk from "./sk.json";
// import ua from "./ua.json";
// import it from "./it.json";
// import fr from "./fr.json";

const translations = { de, en };

export default translations;
