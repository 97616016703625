import { CONSTANTS } from "@xyndata/communication_app_common";
import storage from "./storage";

export const createBrowserId = () => {
  const browserId = `${Date.now()}${navigator.appName}${navigator.platform}`;
  storage.setItem(CONSTANTS.STORAGE_KEYS.BROWSER_ID_KEY, browserId);

  return browserId;
};

export const getBrowserId = () => storage.getItem(CONSTANTS.STORAGE_KEYS.BROWSER_ID_KEY) || createBrowserId();
