import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Alert, Input, Button } from "@xyndata/collaboration_ui";
import { store } from "@xyndata/communication_app_common";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import getNameSpaceFromUrl from "../../helpers/getNameSpaceFromUrl";
import { createBrowserId } from "../../helpers/browserId";
import { saveAuthToken } from "../../helpers/authToken";
import { getApiErrorTranslation } from "../../helpers";
import api from "../../api";
import View from "../../components/ui/View";
import LanguageSelect from "../../components/LanguageSelect";
import ROUTES from "../../constants/routes";
import { userPropTypes } from "../../constants/propTypes";
import { FormContainer, Form, SelectWrapper } from "./GuestJoinIframe.styled";
import useURLSearchParam from "../../hooks/useURLSearchParam";
import logger from "../../helpers/logger";

const GuestJoinIframe = ({ fetchMe, authError, authLoading, changeLanguage, user, logout }) => {
  const intl = useIntl();
  const { push } = useHistory();
  const langValue = useURLSearchParam("lang");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      hash: "",
      nick_name: "",
    },
  });

  const resetField = (name) => setValue(name, "");

  const onSubmit = async ({ nick_name, hash }) => {
    try {
      setLoading(true);
      setError(null);

      let hashMeetingExist = false;
      let slugMeetingExist = false;

      try {
        hashMeetingExist = await api.meetings.getMeetingInfoByHash(hash);
      } catch (hashRequestError) {
        logger.log(hashRequestError);
      }

      if (!hashMeetingExist) {
        slugMeetingExist = await api.meetings.getMeetingInfoBySlug(hash);
      }

      // prevent join not exist meeting
      if (hashMeetingExist || slugMeetingExist) {
        const browserId = createBrowserId();

        const guest = {
          // temp empty email for current client, should be replaced with correct email
          email: "",
          nick_name,
          space_name: getNameSpaceFromUrl(),
          browser_id: browserId,
          tos_accepted: true,
          tos_accepted_at: new Date(),
          // set version 1 just for current client, should be TOS modal and tos version from store
          tos_version: 1,
        };

        const { data } = await api.auth.createGuest(guest);

        if (data?.token) {
          saveAuthToken(data.token, browserId, false);
          fetchMe();
        }
      } else {
        setError(intl.formatMessage({ id: "error.meeting.notexists" }));
      }
    } catch (err) {
      setError(getApiErrorTranslation(intl, err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      logout();
    }
    if (langValue) {
      changeLanguage(langValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { hash } = getValues();
    if (hash && user?.id) {
      const encodedHash = encodeURIComponent(hash);
      push(ROUTES.VIDEOROOM.replace(":hash", encodedHash));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <View p="32px 24px 32px" width="100%">
          <SelectWrapper>
            <LanguageSelect authorized={false} />
          </SelectWrapper>

          <View height="67px" width="100%">
            <Input
              translate="no"
              disabled={false}
              label={intl.formatMessage({ id: "ui.meetingHash" })}
              id="hash"
              name="hash"
              onInputButtonClick={resetField}
              error={errors?.hash?.message}
              autoFocus
              {...register("hash", { required: intl.formatMessage({ id: "ui.requiredField" }) })}
            />
          </View>

          <View height="67px" width="100%">
            <Input
              translate="no"
              disabled={false}
              autoFocus
              label={intl.formatMessage({ id: "ui.nickname" })}
              id="nick_name"
              name="nick_name"
              onInputButtonClick={resetField}
              error={errors?.nick_name?.message}
              {...register("nick_name", { required: intl.formatMessage({ id: "ui.requiredField" }) })}
            />
          </View>

          {(error || authError) && <Alert>{error || authError}</Alert>}

          <View mt="16px" width="100%">
            <Button type="submit" disabled={loading || authLoading} fullWidth>
              {intl.formatMessage({ id: "joinMeeting.joinAsGuest" })}
            </Button>
          </View>
        </View>
      </Form>
    </FormContainer>
  );
};

GuestJoinIframe.propTypes = {
  fetchMe: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  authError: PropTypes.string,
  authLoading: PropTypes.bool.isRequired,
  user: userPropTypes,
  logout: PropTypes.func.isRequired,
};

GuestJoinIframe.defaultProps = {
  authError: null,
  user: null,
};

const mapDispatchToProps = {
  fetchMe: store.auth.actions.requestFetchMe,
  changeLanguage: store.app.actions.setLanguage,
  logout: store.auth.actions.requestLogout,
};

const mapStateToProps = (state) => ({
  authError: store.auth.selectors.selectAuthError(state),
  authLoading: store.auth.selectors.selectAuthLoading(state),
  user: store.auth.selectors.selectUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestJoinIframe);
