/* eslint-disable func-names */
/* eslint-disable object-shorthand */

const storage = {
  isLocalStorageSupported: function () {
    try {
      return "localStorage" in window && window.localStorage !== null;
    } catch (e) {
      return false;
    }
  },

  setItem: function (name, value, days) {
    let expires = "";

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    }

    if (this.isLocalStorageSupported()) {
      localStorage.setItem(name, value);
    } else {
      document.cookie = `${name}=${value}${expires}; path=/`;
    }
  },

  getItem: function (name) {
    if (this.isLocalStorageSupported()) {
      const ret = localStorage.getItem(name);

      switch (ret) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return ret;
      }
    } else {
      // cookie fallback
      /*
       * after adding a cookie like
       * >> document.cookie = "bar=test; expires=Thu, 14 Jun 2018 13:05:38 GMT; path=/"
       * the value of document.cookie may look like
       * >> "foo=value; bar=test"
       */
      const nameEQ = `${name}=`; // what we are looking for
      const ca = document.cookie.split(";"); // split into separate cookies
      for (let i = 0; i < ca.length; i++) {
        let cookie = ca[i]; // the current cookie

        while (cookie.charAt(0) === " ") cookie = cookie.substring(1, cookie.length); // remove leading spaces
        if (cookie.indexOf(nameEQ) === 0) {
          // if it is the searched cookie
          const ret = cookie.substring(nameEQ.length, cookie.length);
          // making "true" and "false" a boolean again.
          switch (ret) {
            case "true":
              return true;
            case "false":
              return false;
            default:
              return ret;
          }
        }
      }
      return null; // no cookie found
    }
  },
  removeItem: function (name) {
    if (this.isLocalStorageSupported()) {
      localStorage.removeItem(name);
    } else {
      this.setItem(name, "", -1);
    }
  },
};

export default storage;
