import { store, CONSTANTS } from "@xyndata/communication_app_common";
import { createIntl, createIntlCache } from "react-intl";
import { takeLatest, call, select, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "../../api";
import { fetchSaga } from "../../helpers/reduxHelpers";
import { isIncludeAlphabets } from "../../helpers/validators";
import translations from "../../translations";

const { meeting: meetingRedux, auth, alerts: alertsStore, app } = store;

export function* joinMeetingSaga(request, { payload }) {
  yield call(fetchSaga, {
    api: request,
    onSuccess: () => meetingRedux.actions.updateMeeting({ joined: true, id: payload }),
    onError: (error) => meetingRedux.actions.joinMeetingError({ error, meetingId: payload }),
    data: payload,
  });
}

export function* loadMeetingSaga(action) {
  const meeting = yield select(meetingRedux.selectors.selectMeeting);
  const user = yield select(auth.selectors.selectUser);
  const isSlug = isIncludeAlphabets(action.payload);

  try {
    const { data } = yield call(
      isSlug ? api.meetings.getMeetingInfoBySlug : api.meetings.getMeetingInfoByHash,
      action.payload
    );

    const hash = !isSlug ? action.payload : data.hash;
    const meetingResponse = { ...data, hash };

    yield put(meetingRedux.actions.updateMeeting(meetingResponse));

    if (user) {
      // try fetch full meeting info for authorized user
      const { data: fullMeeting } = yield call(api.meetings.getByHash, hash);
      yield put(meetingRedux.actions.updateMeeting(fullMeeting));
    }
  } catch (error) {
    // prevent show error on not access error
    if (error.response.status !== 403) {
      yield put(
        meetingRedux.actions.joinMeetingError({
          error: error?.response?.data?.message || "ui.defaultError",
          meetingId: meeting.id,
        })
      );
    }
  }
}

function* removeRequestsSaga(alert, request) {
  if (alert?.payload?.meeting_id === request.meeting_id && alert?.payload?.user_id === request.user_id) {
    yield put(alertsStore.actions.hideAlert(alert.toastId));
    yield call([toast, "dismiss"], alert.toastId);
  }
}

export function* closeAccessRequestAlertSaga({ payload }) {
  const alerts = yield select(alertsStore.selectors.selectAlerts);
  yield all(alerts.map((item) => call(removeRequestsSaga, item, payload)));
}

export function* meetingRecordingRequestSaga({ payload: { meeting_id, recording, created_at } }) {
  const locale = yield select(app.selectors.selectLanguage);
  const cache = createIntlCache();
  const intl = createIntl({ locale, messages: translations[locale] }, cache);

  yield put(
    alertsStore.actions.addInfoAlert(
      intl.formatMessage({ id: recording ? "meeting.recordingIsStarted" : "meeting.recordingWasFinished" })
    )
  );

  yield put(
    meetingRedux.actions.updateMeeting({
      id: meeting_id,
      recording,
      recording_started_at: created_at,
      recordingLoading: false,
    })
  );
}

export function* toggleRecordingSaga({ payload }) {
  const meeting = yield select(meetingRedux.selectors.selectMeeting);

  yield put(meetingRedux.actions.updateMeeting({ id: meeting.id, recordingLoading: true }));
  yield call(fetchSaga, {
    api: api.meetings.toggleRecording,
    onError: (error) => {
      meetingRedux.actions.updateMeeting({ id: meeting.id, recordingLoading: false });
      meetingRedux.actions.joinMeetingError({ error, meetingId: payload });
    },
    data: {
      meetingId: meeting.id,
      data: { togggleRecording: !meeting.recording },
    },
  });
}

export function* loadFullMeetingInfo({ payload }) {
  const meeting = yield select(meetingRedux.selectors.selectMeeting);

  if (meeting.id && meeting.hash && meeting.id === payload.meeting_id) {
    yield call(fetchSaga, {
      api: api.meetings.getByHash,
      data: meeting.hash,
      onSuccess: (data) => meetingRedux.actions.updateMeeting({ ...data, loaded: true, skipWaitingRoom: true }),
      onError: (error) => {
        if (error.response.status !== 403) {
          meetingRedux.actions.joinMeetingError({
            error: error?.response?.data?.message || "ui.defaultError",
            meetingId: meeting.id,
          });
        }
      },
    });
  }

  yield call(closeAccessRequestAlertSaga, { payload });
}

function* meetingSaga() {
  yield takeLatest(meetingRedux.actions.joinMeeting.type, joinMeetingSaga, api.meetings.join);
  yield takeLatest(meetingRedux.actions.loadMeeting.type, loadMeetingSaga);
  yield takeLatest(CONSTANTS.EVENTS_TYPES.MEETING_ACCESS_ACCEPTED, loadFullMeetingInfo);
  yield takeLatest(CONSTANTS.EVENTS_TYPES.MEETING_ACCESS_DECLINED, closeAccessRequestAlertSaga);
  yield takeLatest(CONSTANTS.EVENTS_TYPES.TOGGLE_RECORDING, toggleRecordingSaga);
  yield takeLatest(CONSTANTS.EVENTS_TYPES.MEETING_RECORDING_REQUEST, meetingRecordingRequestSaga);
}

export default meetingSaga;
